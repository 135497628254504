var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "LBLBASEINFO" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.editable && _vm.isOld && !_vm.disabled,
                                expression: "editable && isOld && !disabled",
                              },
                            ],
                            attrs: {
                              url: _vm.completeUrl,
                              isSubmit: _vm.isComplete,
                              param: _vm.utvCheck,
                              mappingType: "PUT",
                              label: "완료",
                              icon: "check",
                            },
                            on: {
                              beforeAction: _vm.completeInfo,
                              btnCallback: _vm.completeCallback,
                            },
                          }),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.editable && !_vm.disabled,
                                expression: "editable && !disabled",
                              },
                            ],
                            attrs: {
                              url: _vm.saveUrl,
                              isSubmit: _vm.isSave,
                              param: _vm.utvCheck,
                              mappingType: _vm.mappingType,
                              label: "LBLSAVE",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveInfo,
                              btnCallback: _vm.saveCallback,
                            },
                          }),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.editable && _vm.isOld && !_vm.disabled,
                                expression: "editable && isOld && !disabled",
                              },
                            ],
                            attrs: { label: "삭제", icon: "delete_forever" },
                            on: { btnClicked: _vm.deleteInfo },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6" },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            disabled: _vm.isOld,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.utvCheck.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.utvCheck, "plantCd", $$v)
                            },
                            expression: "utvCheck.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6" },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            type: "datetime",
                            default: "today",
                            label: "보고일시",
                            name: "reportDate",
                          },
                          model: {
                            value: _vm.utvCheck.reportDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.utvCheck, "reportDate", $$v)
                            },
                            expression: "utvCheck.reportDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                      },
                      [
                        _c("c-fire-fighting-utv", {
                          attrs: {
                            isMultiple: "",
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "UTV 소방차",
                            name: "sopFireFightingUtvIds",
                          },
                          model: {
                            value: _vm.utvCheck.sopFireFightingUtvIds,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.utvCheck,
                                "sopFireFightingUtvIds",
                                $$v
                              )
                            },
                            expression: "utvCheck.sopFireFightingUtvIds",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            rows: 1,
                            label: "내용",
                            name: "contents",
                          },
                          model: {
                            value: _vm.utvCheck.contents,
                            callback: function ($$v) {
                              _vm.$set(_vm.utvCheck, "contents", $$v)
                            },
                            expression: "utvCheck.contents",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            rows: 1,
                            label: "미흡사항",
                            name: "insufficiencies",
                          },
                          model: {
                            value: _vm.utvCheck.insufficiencies,
                            callback: function ($$v) {
                              _vm.$set(_vm.utvCheck, "insufficiencies", $$v)
                            },
                            expression: "utvCheck.insufficiencies",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            rows: 1,
                            label: "조치내역",
                            name: "actionDetails",
                          },
                          model: {
                            value: _vm.utvCheck.actionDetails,
                            callback: function ($$v) {
                              _vm.$set(_vm.utvCheck, "actionDetails", $$v)
                            },
                            expression: "utvCheck.actionDetails",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6" },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            type: "datetime",
                            default: "today",
                            label: "사용일시",
                            name: "useDt",
                          },
                          model: {
                            value: _vm.utvCheck.useDt,
                            callback: function ($$v) {
                              _vm.$set(_vm.utvCheck, "useDt", $$v)
                            },
                            expression: "utvCheck.useDt",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6" },
                      [
                        _c("c-field", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            data: _vm.utvCheck,
                            deptValue: "deptCd",
                            type: "dept_user",
                            label: "사용자",
                            name: "userId",
                          },
                          model: {
                            value: _vm.utvCheck.userId,
                            callback: function ($$v) {
                              _vm.$set(_vm.utvCheck, "userId", $$v)
                            },
                            expression: "utvCheck.userId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            rows: 1,
                            label: "사용내역",
                            name: "usageHistory",
                          },
                          model: {
                            value: _vm.utvCheck.usageHistory,
                            callback: function ($$v) {
                              _vm.$set(_vm.utvCheck, "usageHistory", $$v)
                            },
                            expression: "utvCheck.usageHistory",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            rows: 1,
                            label: "기타사항",
                            name: "etc",
                          },
                          model: {
                            value: _vm.utvCheck.etc,
                            callback: function ($$v) {
                              _vm.$set(_vm.utvCheck, "etc", $$v)
                            },
                            expression: "utvCheck.etc",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            rows: 1,
                            label: "비고",
                            name: "remarks",
                          },
                          model: {
                            value: _vm.utvCheck.remarks,
                            callback: function ($$v) {
                              _vm.$set(_vm.utvCheck, "remarks", $$v)
                            },
                            expression: "utvCheck.remarks",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                      },
                      [
                        _c("c-upload", {
                          attrs: {
                            attachInfo: _vm.attachInfo,
                            editable: _vm.editable && !_vm.disabled,
                            label: "관련 사진",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
            [
              _c(
                "c-table",
                {
                  ref: "table",
                  attrs: {
                    title: "UTV 소방차 점검 항목",
                    columns: _vm.grid.columns,
                    gridHeight: _vm.grid.height,
                    data: _vm.utvCheck.results,
                    filtering: false,
                    columnSetting: false,
                    usePaging: false,
                    hideBottom: "",
                    editable: _vm.editable && !_vm.disabled,
                    selection: "multiple",
                    rowKey: "sopFireFightingUtvCheckResultId",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "LBLADD",
                                  icon: "add",
                                },
                                on: { btnClicked: _vm.addResult },
                              })
                            : _vm._e(),
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  disabled:
                                    !_vm.utvCheck.results ||
                                    _vm.utvCheck.results.length === 0,
                                  label: "제외",
                                  icon: "remove",
                                },
                                on: { btnClicked: _vm.deleteResult },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "UTV소방차 점검사진" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c("c-pick-file", {
                        attrs: {
                          editable: _vm.editable,
                          disabled: _vm.disabled,
                          outlined: "",
                          dense: true,
                        },
                        on: { getImageData: _vm.getImageData },
                        scopedSlots: _vm._u([
                          {
                            key: "prepend",
                            fn: function () {
                              return [
                                _c("q-icon", {
                                  attrs: { name: "attach_file" },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.file,
                          callback: function ($$v) {
                            _vm.file = $$v
                          },
                          expression: "file",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                      },
                      [
                        _c("q-img", {
                          attrs: { src: _vm.utvCheck.utvCheckSrc },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }